window.__VITE__PLUGIN__FAKE__SERVER__={
  "meta": {
    "name": "vite-plugin-fake-server",
    "type": "module",
    "version": "2.2.0",
    "description": "A fake server plugin for Vite.",
    "author": "Condor Hero",
    "license": "MIT",
    "homepage": "https://github.com/condorheroblog/vite-plugin-fake-server/blob/main/README.md",
    "repository": {
      "type": "git",
      "url": "git+https://github.com/condorheroblog/vite-plugin-fake-server.git",
      "directory": "packages/vite-plugin-fake-server"
    },
    "bugs": {
      "url": "https://github.com/condorheroblog/vite-plugin-fake-server/issues"
    },
    "keywords": [
      "vite-plugin-fake-server",
      "vite-plugin-fake",
      "vite-plugin-faker",
      "vite-plugin-mock-server",
      "vite-plugin-mock",
      "faker-server"
    ],
    "exports": {
      ".": {
        "import": "./dist/index.mjs",
        "require": "./dist/index.cjs"
      },
      "./client": {
        "import": "./dist/client.mjs",
        "require": "./dist/client.cjs"
      }
    },
    "main": "dist/index.cjs",
    "module": "dist/index.mjs",
    "types": "dist/index.d.cts",
    "typesVersions": {
      "*": {
        "*": [
          "./dist/*",
          "./dist/index.d.ts"
        ]
      }
    },
    "files": [
      "dist"
    ],
    "scripts": {
      "prepublishOnly": "nr build",
      "test": "vitest",
      "dev": "esno ./src/index.ts",
      "build": "rimraf dist && rollup -c",
      "build:watch": "rimraf dist && rollup -c -w",
      "typecheck": "tsc --noEmit"
    },
    "dependencies": {
      "bundle-import": "^0.0.2",
      "chokidar": "^4.0.1",
      "path-to-regexp": "^8.2.0",
      "picocolors": "^1.1.1",
      "tinyglobby": "^0.2.10"
    },
    "devDependencies": {
      "vite": "^6.0.3"
    },
    "publishConfig": {
      "access": "public",
      "registry": "https://registry.npmjs.org"
    }
  },
  "vitePluginFakeServerOptions": {
    "include": [
      "fake"
    ],
    "exclude": [],
    "infixName": "fake",
    "extensions": [
      "ts",
      "js",
      "mjs",
      "cjs",
      "cts",
      "mts"
    ],
    "enableProd": true,
    "enableDev": true,
    "watch": true,
    "logger": true,
    "basename": "fake-api",
    "headers": {},
    "build": false,
    "http2": true
  }
};