<script setup lang="ts">
const route = useRoute();
definePage({
  meta: {
    hidden: true,
  },
});
</script>

<template>
  <h1>Detail</h1>
  <p>id: {{ route.query.id }}</p>
</template>
