import _definePage_default_0 from '/workspace/examples/vue-ts-example/src/pages/index.page.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/workspace/examples/vue-ts-example/src/pages/data-loaders.[id]/index.page.vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/workspace/examples/vue-ts-example/src/pages/infinite-loading/detail.page.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'Root',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/index.page.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/:path(.*)',
    name: '$Path',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/[...path].page.vue'),
    props: true,
    /* no children */
    meta: {
      "layout": false
    }
  },
  {
    path: '/api',
    name: 'Api',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/api.page.vue'),
    /* no children */
  },
  {
    path: '/data-loaders/:id',
    /* internal name: 'DataLoadersId' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'DataLoadersId',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/data-loaders.[id]/index.page.vue'),
        /* no children */
        meta: {
          "// layout": "second"
        }
      },
  _definePage_default_2
  ),
      {
        path: 'sub-1/:userId',
        name: 'DataLoadersIdSub1UserId',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/data-loaders.[id]/sub-1.[userId].page.vue'),
        /* no children */
        meta: {
          "// layout": "second"
        }
      }
    ],
  },
  {
    path: '/fonts',
    name: 'Fonts',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/fonts.page.vue'),
    /* no children */
  },
  {
    path: '/i18n',
    name: 'I18n',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/i18n.page.vue'),
    /* no children */
  },
  {
    path: '/Icons',
    name: 'Icons',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/Icons.page.vue'),
    /* no children */
  },
  {
    path: '/infinite-loading',
    /* internal name: 'InfiniteLoading' */
    /* no component */
    children: [
      {
        path: '',
        name: 'InfiniteLoading',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/infinite-loading/index.page.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'detail',
        name: 'InfiniteLoadingDetail',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/infinite-loading/detail.page.vue'),
        /* no children */
      },
  _definePage_default_3
  )
    ],
  },
  {
    path: '/JSPage',
    /* internal name: 'JSPage' */
    /* no component */
    children: [
      {
        path: '',
        name: 'JSPage',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/JSPage/index.page.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/MDPage',
    name: 'MDPage',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/MDPage.page.md'),
    /* no children */
  },
  {
    path: '/primevue',
    /* internal name: 'Primevue' */
    /* no component */
    children: [
      {
        path: '',
        name: 'Primevue',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/primevue/index.page.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/ts-enum-util',
    name: 'TsEnumUtil',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/ts-enum-util.page.vue'),
    /* no children */
  },
  {
    path: '/VueMacros',
    /* internal name: 'VueMacros' */
    /* no component */
    children: [
      {
        path: 'DefineRender',
        name: 'VueMacrosDefineRender',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/VueMacros/DefineRender.page.vue'),
        /* no children */
      },
      {
        path: 'ReactivityTransform',
        name: 'VueMacrosReactivityTransform',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/VueMacros/ReactivityTransform.page.vue'),
        /* no children */
      },
      {
        path: 'ReusableTemplate',
        name: 'VueMacrosReusableTemplate',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/VueMacros/ReusableTemplate.page.vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

