<script setup lang="tsx">
const VITE_BUILD_COMMIT = import.meta.env.VITE_BUILD_COMMIT;
import { routes } from 'vue-router/auto-routes';
definePage({ meta: { title: '首页' } });
useHead({
  // Titles
  title: 'Hello World',
  titleTemplate: '%s %separator %siteName',
  // Template params
  templateParams: { separator: '|', siteName: 'My App' },
  // Classes
  bodyAttrs: { class: { overflow: true } },
  // Deduping
  // script: [{ key: '123', src: '/script.js' }],
});

consola.info('routes', routes);

const FComponent: import('vue').FunctionalComponent<{ prop: string }> = (props, context) => (
  <>
    <a
      class="green"
      target="_blank"
      rel="noopener noreferrer"
      href="https://cn.vuejs.org/guide/extras/render-function#typing-functional-components"
    >
      函数式组件: https://cn.vuejs.org/guide/extras/render-function#typing-functional-components
    </a>
    <p>函数式组件，接收到的 prop 值为：</p>
    <pre>{JSON.stringify(props, null, 2)}</pre>
  </>
);
</script>

<template>
  <div b="1px solid pink" mt-2 p-2>
    <ul>
      <li>
        <router-link class="green" :to="{ name: 'DataLoadersId', params: { id: 520 } }">Data Loaders</router-link>
      </li>
    </ul>
  </div>
  <div b="1px solid pink" mt-2 p-2>
    <FComponent prop="Hello World" style="margin-top: 1rem"></FComponent>
  </div>

  <SendSms class="mt-2!" />
  <div b="1px solid pink" mt-2 p-2>commit: {{ VITE_BUILD_COMMIT }}</div>

  <div :class="$style.hero" mt-2>
    <h1><i>🔌</i> Vite Plugin Webfont DL <i>⚡</i></h1>
    <h2>Fonts are downloaded directly from Google Fonts</h2>
    <pre>{{ JSON.stringify({ $style }, null, 2) }}</pre>
  </div>
</template>

<style module>
/* https://cn.vuejs.org/api/sfc-css-features#css-modules */
h1 {
  font-family: 'Press Start 2P', cursive;
  color: #646cff;
}

h2 {
  font-family: 'Fira Code', monospace;
  background-color: #42b983;
}

.hero {
  border: 1px solid #42b983;
}
</style>
