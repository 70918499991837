import { DataLoaderPlugin } from 'unplugin-vue-router/data-loaders';
import { createGetRoutes, setupLayouts } from 'virtual:meta-layouts';
// import { createGetRoutes, setupLayouts } from 'virtual:generated-layouts';
import { createRouter, createWebHistory } from 'vue-router/auto';
import { handleHotUpdate, routes } from 'vue-router/auto-routes';
const setupLayoutsResult = setupLayouts(routes);
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: /* routes ?? */ setupLayoutsResult,
  strict: true,
  scrollBehavior: (_to, _from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});
if (import.meta.hot) handleHotUpdate(router);
if ($__DEV__) Object.assign(window, { router });
router.onError((error) => {
  console.debug('🚨 [router error]: ', error);
});

export { router, setupLayoutsResult, createGetRoutes };
export function install({ app }: { app: import('vue').App<Element> }) {
  app
    // Register the plugin before the router
    .use(DataLoaderPlugin, { router })
    // adding the router will trigger the initial navigation
    .use(router);
}
// ========================================================================
// =========================== Router Guards ==============================
// ========================================================================
{
  // 警告：路由守卫的创建顺序会影响执行流程，请勿调整
  createNProgressGuard(router);
  createLogGuard(router);
  Object.assign(window, { stack: createStackGuard(router) });
}

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * @description 是否在菜单中隐藏
     */
    hidden?: boolean;
    /**
     * @description 菜单标题
     */
    title?: string;
  }
}
