/**
 * 需要把 <DynamicDialog /> <ConfirmDialog /> <Toast /> 放在 App.vue 的 template 中
 */

import Aura from '@primevue/themes/aura';
import zhCN from 'primelocale/zh-CN.json';
import PrimeVue from 'primevue/config';
import StyleClass from 'primevue/styleclass';

export function install({ app }: { app: import('vue').App<Element> }) {
  app.directive('styleclass', StyleClass);
  app.use(PrimeVue, {
    locale: {
      ...zhCN['zh-CN'],
      noFileChosenMessage: '未选择文件',
      pending: '待上传',
      completed: '已上传',
    }, // usePrimeVue().config.locale
    theme: {
      preset: Aura,
      options: {
        prefix: 'p',
        darkModeSelector: '.app-dark' /* 'system' */,
        cssLayer: false,
      },
    },
  });
}
